import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import { initializeSdk, TemplatesSdk } from './sdk';
import {
	ConstructTemplateParams,
	CopyTemplateParams,
	CreateRunParams,
	DeleteTemplateParams,
	GetRunLogsParams,
	GetRunParams,
	GetTemplateParams,
	ListRunsParams,
	ListTemplatesParams,
	UpdateTemplateParams,
} from './types';

/**
 * Wraps the Templates v2 Axios SDK (see ./sdk.ts)
 */
export class TemplatesClient {
	private initializedSDK?: TemplatesSdk;

	public Initialize(sdk: TemplatesSdk) {
		this.initializedSDK = sdk;
	}

	public listTemplates(
		params?: Omit<ListTemplatesParams, 'filter' | 'searchTerm' | 'enabled'>
	) {
		return this.initializedSDK?.listTemplates(params);
	}

	public getTemplate(params: GetTemplateParams) {
		return this.initializedSDK?.getTemplate(params);
	}

	public createRun(params: CreateRunParams) {
		return this.initializedSDK?.createRun(params);
	}

	public deleteTemplate(params: DeleteTemplateParams) {
		return this.initializedSDK?.deleteTemplate(params);
	}

	public getRun(params: GetRunParams) {
		return this.initializedSDK?.getRun(params);
	}

	public constructTemplate(params: ConstructTemplateParams) {
		return this.initializedSDK?.constructTemplate(params);
	}

	public getRunLogs(params: GetRunLogsParams) {
		return this.initializedSDK?.getRunLogs(params);
	}

	public listRuns(params: ListRunsParams) {
		return this.initializedSDK?.listRuns(params);
	}

	public copyTemplate(params: CopyTemplateParams) {
		return this.initializedSDK?.copyTemplate(params);
	}

	public updateTemplate(params: UpdateTemplateParams) {
		return this.initializedSDK?.updateTemplate(params);
	}
}

export const templatesClient = new TemplatesClient();

export function configureTemplatesClient(app: PiletApi) {
	templatesClient.Initialize(
		initializeSdk({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
