/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */
import type { Tag } from '@sharefile/tags-client';
import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
import type { FormData } from '@sharefiledev/workflows-pilet';
import type { GetTemplateRunResponse } from '../data/clients';
import type { LocaleString } from '../utils';

const prefix = 'urn';
const owner = 'templates-pilet';
const modalNamespace = 'sfmodal';
const drawerNamespace = 'sfdrawer';
const extensionNamespace = 'sfextslot';
const resourceType = 'template';

export type TemplateListTabKey =
	| 'my-templates'
	| 'shared-with-me'
	| 'installed-templates'
	| 'run-history';

export const templatesPiletExtensionSlots = {
	editProperties:
		`${prefix}:${extensionNamespace}:${resourceType}:package-editor-title-slot` as const,
	runTemplateWithTag:
		`${prefix}:${extensionNamespace}:${resourceType}:run-template-with-tag` as const,
};

export const templatesPiletOverlays = {
	selectTemplate: `${prefix}:${modalNamespace}:${owner}:select-template` as const,
	useTemplate: `${prefix}:${modalNamespace}:${owner}:use-template` as const,
	useTemplateDrawer: `${prefix}:${drawerNamespace}:${owner}:use-template` as const,
	saveAsTemplate: `${prefix}:${modalNamespace}:${owner}:save-as-template` as const,
};

export const internalTemplatesPiletOverlays = {
	templateDetails: `${prefix}:${modalNamespace}:${owner}:template-details` as const,
	templateProperties:
		`${prefix}:${drawerNamespace}:${owner}:template-properties` as const,
	templateDuplicate: `${prefix}:${drawerNamespace}:${owner}:template-duplicate` as const,
};

export type EditPropertiesExtensionParams = {
	rid: string;
};

export type RunTemplateWithTagParams = {
	resourceType: string;
	templateMeta: {
		name: string;
		value: string;
	};
	tag: Tag;
	drawerTitle: string;
	drawerOpenEventUrn: string;
	onCompleteInteraction: (RID: string | CreateTemplateSuccessReturnData) => void;
	pendoTrackingNamespace?: string;
	footerButtonLabels?: {
		buttonText?: LocaleString;
		cancelButtonText?: LocaleString;
	};
	requiredResponseObj?: boolean;
};

export type Context = { [key: string]: any };

export type BaseTemplateContext = Context;

export type BaseTemplateOverlayParams = {
	resourceType: string;
	containerRID?: string;
	templateRID?: string;
	tags?: Tag[];
	context?: SelectTemplateContext;
};

export type UseTemplateOverlayParams = BaseTemplateOverlayParams & {
	onCompleteNavigationPath?: string;
	buttonText?: LocaleString;
	cancelButtonText?: LocaleString;
	requiredResponseObj?: boolean;
	showTemplateTitle?: boolean;
	isDrawer?: boolean;
	showActionableMessage?: boolean;
	pendoTrackingNamespace?: string;
};

export type SelectTemplateContext = Context;

export type SelectTemplateOverlayParams = BaseTemplateOverlayParams & {
	defaultTabKey?: TemplateListTabKey;
	showSolutionTemplatesOnly?: boolean;
	includeHidden?: boolean;
	setData?: (templateRID: string) => void;
};

export type UseTemplateDrawerOverlayParams = {
	params: UseTemplateOverlayParams;
	/** @deprecated */
	context?: BaseTemplateContext;
};

export type SaveAsTemplateOverlayParams = {
	resourceType: string;
	context: {
		RID: string;
		metadata?: {
			name?: string;
			description?: string;
		};
	};
};

export type SaveAsTemplateOverlayDataMap = {
	templateRID: string;
};

export type TemplateDetailsOverlayParams = {
	templateRID: string;
};

export type TemplatePropertiesOverlayParams = {
	templateRID: string;
};

export type TemplateDuplicateOverlayParams = {
	template: {
		RID: string;
		name: string;
		description: string;
	};
};

export type CreateTemplateSuccessReturnData = {
	templateRun: GetTemplateRunResponse;
	formData: FormData['data'];
	templateName: string;
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[templatesPiletExtensionSlots.editProperties]: EditPropertiesExtensionParams;
		[templatesPiletExtensionSlots.runTemplateWithTag]: RunTemplateWithTagParams;
	}

	interface PiralCustomOverlayOptionsMap {
		[templatesPiletOverlays.selectTemplate]: SelectTemplateOverlayParams;
		[templatesPiletOverlays.useTemplate]: UseTemplateOverlayParams;
		[templatesPiletOverlays.useTemplateDrawer]: UseTemplateDrawerOverlayParams;
		[templatesPiletOverlays.saveAsTemplate]: SaveAsTemplateOverlayParams;
		[internalTemplatesPiletOverlays.templateDetails]: TemplateDetailsOverlayParams;
		[internalTemplatesPiletOverlays.templateProperties]: TemplatePropertiesOverlayParams;
		[internalTemplatesPiletOverlays.templateDuplicate]: TemplateDuplicateOverlayParams;
	}

	interface PiralCustomOverlayDataMap {
		[templatesPiletOverlays.saveAsTemplate]: SaveAsTemplateOverlayDataMap;
		[templatesPiletOverlays.useTemplateDrawer]: CreateTemplateSuccessReturnData | string;
	}
}
