import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Skeleton } from 'antd';
import { BlockNames } from '../block.types';

export function registerBlocks(piral: PiletApi) {
	const LazyCreateFromSpecificTemplateButton = React.lazy(
		() => import('./CreateFromSpecificTemplateButton')
	);
	piral.registerExtension(BlockNames.CreateFromSpecificTemplateButton, props => (
		<React.Suspense fallback={<Skeleton />}>
			<LazyCreateFromSpecificTemplateButton {...props} />
		</React.Suspense>
	));

	const LazyCreateFromTemplateWithTagButton = React.lazy(
		() => import('./CreateFromTemplateWithTagButton')
	);
	piral.registerExtension(BlockNames.CreateFromTemplateWithTagButton, props => (
		<React.Suspense fallback={<Skeleton />}>
			<LazyCreateFromTemplateWithTagButton {...props} />
		</React.Suspense>
	));
}
