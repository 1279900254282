/* tslint:disable */
/**
 * This file is autogenerated using @sharefiledev/solution-view-engine-tools.
 *
 * Run 'sve typegen <relative path to manifest file>'
 *
 * Types are generated according to specification in a file that conforms to the UI Manifest schema:
 * https://us-solutionviewengine.sharefiletest.io/ui-manifest-v2.json
 */
import type {
	PiletApi,
	PiralCustomExtensionSlotMap as ShellPiralCustomExtensionSlotMap,
} from '@sharefiledev/sharefile-appshell';
import type { BlockParams } from '@sharefiledev/solution-view-engine';

export interface ShellProps {
	piletApi: PiletApi;
}
/** Block parameters for urn:block:templates-pilet:create-from-specific-template-button */

export interface CreateFromSpecificTemplateButtonBlockParams {
	templateRID: string;
	showTemplateMetadata?: boolean;
	resourceType: string;
	buttonText: LocalizedString;
	titleText?: LocalizedString;
	cancelButtonText?: LocalizedString;
	/**
	 * The type of button to render
	 */
	type?: 'primary' | 'link' | 'text' | 'default' | 'dashed';
	/**
	 * The size of the button
	 */
	size?: 'small' | 'middle' | 'large';
	onCompleteNavigationPath?: string;
	verticalContextKey?: 'accounting';
	context?: {};
}
/**
 * Localized String Values
 */
export interface LocalizedString {
	en?: string;
	es?: string;
	de?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}
/** Block parameters for urn:block:templates-pilet:create-from-template-with-tag-button */

export interface CreateFromTemplateWithTagButtonBlockParams {
	templateRID?: string;
	defaultTabKey?:
		| 'my-templates'
		| 'shared-with-me'
		| 'installed-templates'
		| 'run-history';
	tags: Tag[];
	disableTuning?: boolean;
	resourceType: string;
	buttonText: LocalizedString;
	cancelButtonText?: LocalizedString;
	titleText?: LocalizedString;
	pickerTitleText?: LocalizedString;
	/**
	 * The type of button to render
	 */
	type?: 'primary' | 'link' | 'text' | 'default' | 'dashed';
	/**
	 * The size of the button
	 */
	size?: 'small' | 'middle' | 'large';
	onCompleteNavigationPath?: string;
	verticalContextKey?: 'accounting';
}
export interface Tag {
	name: string;
	value: string;
}
/**
 * Localized String Values
 */
export interface LocalizedString {
	en?: string;
	es?: string;
	de?: string;
	fr?: string;
	it?: string;
	ja?: string;
	nl?: string;
	'pt-BR'?: string;
}

export namespace BlockNames {
	export const CreateFromSpecificTemplateButton =
		'urn:block:templates-pilet:create-from-specific-template-button' as const;
	export type CreateFromSpecificTemplateButton =
		'urn:block:templates-pilet:create-from-specific-template-button';
	export const CreateFromTemplateWithTagButton =
		'urn:block:templates-pilet:create-from-template-with-tag-button' as const;
	export type CreateFromTemplateWithTagButton =
		'urn:block:templates-pilet:create-from-template-with-tag-button';
}

export namespace ZoneTypes {}

export type CreateFromSpecificTemplateButtonProps = ShellProps &
	BlockParams &
	CreateFromSpecificTemplateButtonBlockParams;
export type CreateFromTemplateWithTagButtonProps = ShellProps &
	BlockParams &
	CreateFromTemplateWithTagButtonBlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		['urn:block:templates-pilet:create-from-specific-template-button']: BlockParams &
			CreateFromSpecificTemplateButtonBlockParams;
		['urn:block:templates-pilet:create-from-template-with-tag-button']: BlockParams &
			CreateFromTemplateWithTagButtonBlockParams;
	}
}

declare module '@sharefiledev/solution-view-engine' {
	interface BlockCustomExtensionSlotMap extends ShellPiralCustomExtensionSlotMap {}

	interface PiletExtensionApi extends PiletApi {}

	interface ViewEngineCustomZoneParametersMap {}

	interface ViewEngineCustomZoneContractDataMap {}
}
